import React, { useState } from "react"
import ChevronToggle from "../page-layout/ui/chevron-toggle"
import Button from "../buttons/button"
import AppleDownloadAppStoreIcon from "../icons/apple-download-app-store"
import GooglePlayDownloadIcon from "../icons/google-play-download"
import PeakTransitImage from "../../images/sections/transportation/trico-transit-rider-portal-screenshot.png"

const PeakTransit = () => {
  const [peakTransitToggle, setPeakTransitToggle] = useState(true)
  const peakTransitVisibility = !peakTransitToggle ? "tw-sr-only" : ""
  return (
    <>
      <div className="mt-3 mb-6">
        <h2>
          <button
            data-type="toggle"
            data-context="transportation section"
            data-action={peakTransitToggle ? "collapse" : "expand"}
            data-label="Peak Transit"
            className="mb-3 text-xl font-bold"
            type="button"
            onClick={() => setPeakTransitToggle(!peakTransitToggle)}
          >
            <span className="pointer-events-none flex flex-row items-center justify-between">
              {" "}
              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-800 ring-1 ring-inset ring-green-600/20 mr-1">
                New
              </span>{" "}
              TriCo Transit Network
              <span className="text-base">
                <ChevronToggle
                  chevronClasses={["ml-2"]}
                  toggle={peakTransitToggle}
                />
              </span>
            </span>
          </button>
        </h2>
        <div className={peakTransitVisibility}>
          <div className="mt-3 mb-6 flex flex-col gap-5 lg:flex-row lg:items-center">
            <a
              href="https://tricotransit.rider.peaktransit.com/?all=1&center=39.978151,-75.340374&zoom=13"
              target="_blank"
              className="md:w-2/3 h-auto"
            >
              <img
                src={PeakTransitImage}
                alt="TriCo Transit Network Live Map"
                className="rounded-md"
              />
            </a>
            <div>
              <p className="mt-0 prose dark:prose-dark">
                The new TriCo Transit Network combines the previous Garnet and
                TriCo shuttles into a single, integrated system.
              </p>
              <div className="my-2">
                <Button
                  color="primary"
                  icon="map"
                  url="https://tricotransit.rider.peaktransit.com/?all=1&center=39.978151,-75.340374&zoom=13"
                  additionalAttr={{
                    target: "_blank",
                    rel: "noreferrer",
                    style: {
                      width: "fit-content",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    },
                  }}
                >
                  View TriCo Transit Network Live Map
                </Button>
              </div>
              <span className="flex gap-2">
                <a
                  href="https://apps.apple.com/us/app/trico-transit-network/id6736896078"
                  target="_blank"
                  rel="noreferrer"
                  name="Download on the Apple App Store"
                >
                  <AppleDownloadAppStoreIcon
                    iconClasses="mt-1"
                    width="6rem"
                    // darkMode={darkMode}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.bishoppeaktech.android.trico"
                  target="_blank"
                  rel="noreferrer"
                  name="Download on the Google Play Store"
                  style={{ paddingTop: "1px" }}
                >
                  <GooglePlayDownloadIcon
                    iconClasses="mt-0"
                    width="7.5rem"
                    // darkMode={darkMode}
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PeakTransit
