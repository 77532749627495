import * as React from "react"
// import { useStaticQuery, graphql } from 'gatsby'

import PeakTransit from "./transportation_peak-transit"
import SeptaFeeds from "./transportation_septa"

const TransportationFeeds = () => {
  return (
    <>
      <PeakTransit />
      <SeptaFeeds />
    </>
  )
}

export default TransportationFeeds
